<template>
  <div class="plan-heading">
    <div class="header-1">Choose your plan</div>

    <div class="toggle-container">
      <button
        class="toggle-option"
        :class="{ active: !isAnnual }"
        @click="setAnnual(false)"
      >
        Monthly
      </button>

      <button
        class="toggle-option"
        :class="{ active: isAnnual }"
        @click="setAnnual(true)"
      >
        Annual
      </button>
    </div>
  </div>

  <div class="plan-content">
    <PlanCard
      v-for="(plan, index) in plans"
      :key="index"
      class="plan-card"
      :planTitle="plan.title"
      :planDescription="plan.description"
      :priceMonthly="plan.priceMonthly"
      :priceAnnual="plan.priceAnnual"
      :features="plan.features"
      :isAnnual="isAnnual"
      :btnLabel="plan.btnLabel"
      @selectPlan="redirectToCheckout"
    />
  </div>

  <div class="plan-content-description description gray-text">
    Credits included in subscriptions do not carry over from month to month<br>1 credit is about 1 seconds of animation for basic MoCap and animation generation
  </div>
</template>

<script setup>
import PlanCard from "@/components/PlanCard.vue";

import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const store = useStore();

const isAnnual = ref(false);

function setAnnual(value) {
  isAnnual.value = value
}

const plans = ref([
  {
    title: "Freemium",
    description: "Explore AvaCapo features for free",
    priceMonthly: "Free",
    priceAnnual: "Free",
    features: [
      "300 credits per month",
      "No credit card required",
      "Non-commercial license",
    ],
  },
  {
    title: "Basic",
    description: "A great start for individuals enhancing productivity with AI",
    priceMonthly: 20,
    priceAnnual: 16,
    features: ["900 credits per month", "General commercial terms"],
  },
  {
    title: "Pro",
    description: "All features for professionals to boost efficiency",
    priceMonthly: 100,
    priceAnnual: 80,
    features: [
      "6 000 credits per month",
      "Faster processing",
      "Yearly access to beta-features",
      "Individual support",
      "General commercial terms",
    ],
  },
  {
    title: "Enterprise",
    description: "Custom solutions for organisations at scale",
    priceMonthly: "Individual Terms",
    priceAnnual: "Individual Terms",
    features: [
      "Customize your subscription according to your needs",
      "Faster processing",
      "Share data between team members",
      "Individual support",
      "General commercial terms",
    ],
    btnLabel: "Contact Sales",
  },
]);

function redirectToCheckout(plan) {
  if (plan.title.toLowerCase() === "enterprise") {
    sendEnterpriseInquiry();
  } else {
    store.commit("setSelectedPlan", plan);
    router.push("/checkout");
  }
}

function sendEnterpriseInquiry() {
  const emailAddress = "sales@avacapo.com"; 
  const subject = encodeURIComponent("Enterprise Plan Inquiry");
  const body = encodeURIComponent(
    `Hello,\n\nI am interested in the Enterprise plan.\n\nPlease provide more details.\n\nThank you!`
  );

  const mailtoLink = `mailto:${emailAddress}?subject=${subject}&body=${body}`;
  window.location.href = mailtoLink;
}
</script>

<style scoped>
.plan-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.header-1 {
    margin-bottom: 5%;  
}

.plan-content {
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.plan-card {
  width: 20%;
}

.toggle-container {     
  display: inline-flex;
  width: 60%;
  justify-content: space-between;
  padding: 2px;
  background-color: rgba(255, 255, 255, .1);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 10%;                  
}

.toggle-option {
    width: 4.6rem;
    text-align: center;
    padding: 5px 10px;
    font-size: .7rem;
    color: white;
    background-color: rgba(255,255,255,.1);
    border-radius: 12px;
    transition-duration: .5s;
}

.toggle-option.active {
  color: black;
  background-color: rgba(255,255,255, .7);
}

.plan-content-description {
  text-align: center;
  margin-bottom: 2rem;
}

@media (max-width: 970px) {
  .plan-content {
    flex-direction: column;
    align-items: center;
  }

  .plan-card {
    width: 90%;
    margin-bottom: 1rem;
  }
}
</style>
