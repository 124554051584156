<template>
  <div  v-if="visible" 
        class="context-menu fixed-container" 
        :style="styleObject" 
        ref="contextMenu">
    <ul>
      <li class="locked">
        <BaseIcon iconName="settings" 
                  class="item-main-icon"
                  :width="25"
                  :height="25"
                  color="rgba(255,255,255,0.4)">
        </BaseIcon>
        Profile settings
      </li>

      <HoverPopover message="Do you have any questions regarding our service? If you have suggestions to share with us please send an email to <span class='gradient-text'>support@avacapo.com</span>">
        <li @click="handleItemProfileSettings">
          <BaseIcon iconName="help" 
                    class="item-main-icon"
                    :width="25"
                    :height="25">
          </BaseIcon>
          
            Support & Feedback
          
        </li>
      </HoverPopover>

      <li class="locked">
        <BaseIcon iconName="credit" 
                  class="item-main-icon"
                  :width="25"
                  :height="25"
                  color="rgba(255,255,255,0.4)">
        </BaseIcon>
        Buy credits
        <BaseIcon iconName="lock" 
                  class="lock-icon"
                  :width="25"
                  :height="25"
                  color="rgba(255,255,255,0.4)">
        </BaseIcon>
      </li>

      <li @click="seePlans">
        <BaseIcon iconName="help" 
                  class="item-main-icon"
                  :width="25"
                  :height="25">
        </BaseIcon>
        See plans
      </li>

      <li @click="logout" class="logout-item">
        <BaseIcon iconName="logout" 
                  class="item-main-icon"
                  :width="25"
                  :height="25">
        </BaseIcon>
        Log out
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onUnmounted, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import BaseIcon from '@/components/BaseIcon.vue';
import HoverPopover from './HoverPopover.vue';

const store = useStore();
const router = useRouter();
const visible = ref(false);
const contextMenu = ref(null);

const styleObject = reactive({
  position: 'fixed',
  zIndex: 500,
  visibility: 'hidden'
});

function showMenu(event) {
  event.stopPropagation();
  const elementRect = event.currentTarget.getBoundingClientRect();
  const menuWidth = 250;

  // Calculate the initial left position
  let left = elementRect.right - menuWidth;
  if (left < 0) {
    left = 0;
  } else if (left + menuWidth > window.innerWidth) {
    left = window.innerWidth - menuWidth;
  }

  visible.value = true;
  styleObject.visibility = 'hidden'; // Initially hide to prevent flickering

  nextTick(() => {
    if (!contextMenu.value) {
      console.error('Context menu element is not available.');
      return;
    }

    const menuHeight = contextMenu.value.offsetHeight;

    // Calculate the bottom position to place the menu directly above the triggering element
    let bottom = window.innerHeight - elementRect.top;

    // If there isn't enough space above, place it below the element
    if (bottom + menuHeight > window.innerHeight) {
      bottom = window.innerHeight - elementRect.bottom;
    }

    // Apply calculated positions
    styleObject.left = `${left}px`;
    styleObject.bottom = `${bottom}px`;
    styleObject.visibility = 'visible';

    // Debugging logs
    console.log('Menu Height:', menuHeight);
    console.log('Element Rect:', elementRect);
    console.log('Calculated Bottom:', bottom);
  });
}


function hideMenu() {
  visible.value = false;
}

function logout() {
  store.dispatch('logout').then(() => {
    router.push('/login');
  });
}

function seePlans() {
  router.push('/plans');
}

function handleClickOutside(event) {
  if (visible.value && contextMenu.value && !contextMenu.value.contains(event.target)) {
    hideMenu();
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});

defineExpose({
  showMenu,
  hideMenu
});
</script>

<style scoped>
.context-menu {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  width: 250px;
  position: fixed;
}

.context-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.context-menu li {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.item-main-icon {
  margin-right: 10px;
}

.lock-icon {
  margin-left: auto;
}

.logout-item {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.locked {
  color: rgba(255,255,255,0.4);
  cursor: not-allowed !important;
}
</style>
