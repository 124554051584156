<template>
  <div class="checkout-summary-wrapper">
    <div class="plan-details">
      <h1 class="header-2">Plan Summary</h1>
      <p class="body-text gray-text ">{{ plan?.title }}</p>
      <p class="header-2">{{ plan?.price }}$/month</p>
      <p class="caption gray-text ">{{ plan?.description }}</p>
      <ul class="caption gray-text">
        <li v-for="(feature, index) in plan?.features" :key="index">
          {{ feature }}
        </li>
      </ul>
    </div>
    <div class="payment-terms">
      <div id="paypal-button-container"></div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { onMounted } from "vue";

const store = useStore();
const plan = store.state.selectedPlan;

onMounted(() => {
  if (window.paypal) {
    renderPayPalButton();
  }
});

function renderPayPalButton() {
  window.paypal
    .Buttons({
      createSubscription(data, actions) {
        return actions.subscription.create({
          plan_id: "YOUR_PLAN_ID",

        });
      },
      onApprove(data) {
        alert(`Subscription successful! Subscription ID: ${data.subscriptionID}`);
      },
      onError(err) {
        console.error("PayPal Error:", err);
      },
      style: {
        color:  'black',
      }
    })
    .render("#paypal-button-container");
}
</script>

<style scoped>
  .checkout-summary-wrapper {
    height: 60%;
    display: flex;
    background-color: rgba(255, 255, 255, 0.1); 
    border-radius: 2.5rem;
  }

  .payment-terms, 
  .plan-details {
    padding: 2.5rem;
    width: 50%;
  }

  .payment-terms {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 2.5rem;
  }
</style>