import { createStore } from 'vuex';
import Cookies from 'js-cookie';

const store = createStore({
    state: {
        sessionId: Cookies.get('sessionId') || '',
        userFolderUrl: null,
        email: Cookies.get('email') || '',
        redirectMessage: null,
        activePlan: 'Freemium',
        selectedPlan: null,
    },
    mutations: {
        setSessionId(state, sessionId) {
            state.sessionId = sessionId;
            Cookies.set('sessionId', sessionId, { expires: 7 });
        },
        clearSessionId(state) {
            state.sessionId = '';
            Cookies.remove('sessionId');
        },
        setUserFolderUrl(state, userFolderUrl) {
            state.userFolderUrl = userFolderUrl;
        },
        setEmail(state, email) {
            state.email = email;
            Cookies.set('email', email, { expires: 7 });
        },
        setRedirectMessage(state, message) {
            state.redirectMessage = message;
        },
        setSelectedPlan(state, plan) {
            state.selectedPlan = plan;
        },
    },
    actions: {
        login({ commit }, { sessionId, userFolderUrl, email }) {
            commit('setSessionId', sessionId);
            commit('setUserFolderUrl', userFolderUrl);
            commit('setEmail', email);
        },
        logout({ commit }) {
            commit('clearSessionId');
            commit('setUserFolderUrl', null);
            commit('setEmail', '');
            Cookies.remove('email');
        },
        setRedirectMessage({ commit }, message) {
            commit('setRedirectMessage', message);
        },
    },
    getters: {
        isLoggedIn: state => {
            return state.sessionId !== '';
        },
        getSessionId: state => {
            return state.sessionId;
        },
        getUserFolderUrl: state => {
            return state.userFolderUrl;
        },
        getEmail: state => {
            return state.email;
        },
        getRedirectMessage: state => {
            return state.redirectMessage;
        },
    }
});

export default store;
