<template>
    <div class="viewer-wrapper">
        <header class="anim-view-header">
            <div class="home-btn control-btn" @click="handleHomeButton">
                <BaseIcon iconName="home"></BaseIcon>
            </div>

            <div class="logo">
                <img src="@/assets/logo.png" alt="Logo" class="logo" />
                <h1 class="header-1">AvaCapo</h1>
            </div>

            <div class="controls">
                <div class="settings-container">
                    <div class="settings-btn control-btn" @click="handleOpenSettings">
                        <BaseIcon iconName="settings">
                        </BaseIcon>
                    </div>

                    <div v-if="showSettingsDropdown" @click="handleCloseSettings">
                        <div class="modal-window" @click.stop>
                            <div v-if="!isThreeModelViewerLoading" class="animation-details">
                                <div class="modal-label">Name</div>
                                <div class="name">
                                    <span class="name-label" v-if="!isEditingName" @click="isEditingName = true">
                                        {{ animationName }}
                                    </span>
                                    <input v-else v-model="animationName" @blur="saveAnimationName"
                                        @keyup.enter="saveAnimationName" />
                                </div>
                                <div class="modal-label">Prompt</div>
                                <div class="prompt">
                                    <span>
                                        {{ promt }}
                                    </span>
                                </div>

                                <div class="modal-label">Favorite</div>
                                <div class="favorite-btn" @click.stop="toggleFavorite">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        :class="{ 'filled': isFavorite, 'outlined': !isFavorite }">
                                        <path
                                            d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
                                            :fill="isFavorite ? '#FFD700' : 'none'"
                                            :stroke="isFavorite ? '#FFD700' : '#FFFFFF'" stroke-width="1.5" />
                                    </svg>
                                </div>

                                <div class="modal-label">Playback Speed</div>
                                <div class="speed-slider">
                                    <input type="range" min="0.1" max="2" step="0.1" v-model="playbackSpeed"
                                        @input="updatePlaybackSpeed" />
                                    <span class="modal-label speed-value">
                                        {{ formattedPlaybackSpeed }}x
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="download-container">
                    <div class="control-btn" @click="handleDownloadIconClick">
                        <BaseIcon iconName="download"></BaseIcon>
                    </div>

                    <div v-if="showFormatDropdown" @click="closeDropdown">
                        <div class="modal-window" @click.stop>
                            <div v-if="isDownloading">
                                <div class="progress-placeholder">
                                    <svg class="progress-circle" width="100" height="100">
                                        <defs>
                                            <radialGradient id="progress-gradient" cx="50%" cy="50%" r="50%">
                                                <stop offset="26%" stop-color="#20AD8B" />
                                                <stop offset="76%" stop-color="#ACE41B" />
                                            </radialGradient>
                                        </defs>
                                        <g transform="rotate(-90, 50, 50)">
                                            <circle class="progress-circle-bg" cx="50" cy="50" r="45" fill="transparent"
                                                stroke="#eee" stroke-width="10" />
                                            <circle class="progress-circle-fg" cx="50" cy="50" r="45" fill="transparent"
                                                stroke="url(#progress-gradient)" stroke-width="10"
                                                :stroke-dasharray="circumference"
                                                :stroke-dashoffset="circumference - loadingProgress * circumference"
                                                stroke-linecap="round" />
                                        </g>
                                    </svg>
                                </div>
                                <p>Downloading...</p>
                            </div>

                            <div v-else>
                                <div class="modal-label">Format</div>
                                <div class="format-buttons">
                                    <div class="format-btn" @click="handleDownload('glb')">
                                        GLB
                                    </div>
                                    <div class="format-btn" @click="handleDownload('fbx')">
                                        FBX
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <div class="app-three-model-viewer">
            <ThreeModelViewer ref="threeModelViewer" :animationId="animationId"
                @loading-state-changed="handleLoadingStateChanged" />
        </div>
    </div>

</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from "vuex";

import ThreeModelViewer from '../components/ThreeModelViewer.vue';
import BaseIcon from '@/components/BaseIcon.vue';

const route = useRoute();
const router = useRouter();
const store = useStore();

const animationId = route.query.animationId;
const sessionId = store.getters.getSessionId;

const threeModelViewer = ref(null);

const showFormatDropdown = ref(false);
const showSettingsDropdown = ref(false);

const animationName = ref("");
const isFavorite = ref(false);
const promt = ref("");

const isEditingName = ref(false);
const isDownloading = ref(false);
const loadingProgress = ref(0);
const loaderRadius = 45;
const circumference = computed(() => 2 * Math.PI * loaderRadius);

const isThreeModelViewerLoading = ref(true);

const playbackSpeed = ref(1);
const formattedPlaybackSpeed = computed(() =>

    parseFloat(playbackSpeed.value).toFixed(1)
);

function updatePlaybackSpeed() {
    threeModelViewer.value.setPlaybackSpeed(playbackSpeed.value);
}

function handleLoadingStateChanged(isLoading) {
    isThreeModelViewerLoading.value = isLoading;
}

async function saveAnimationName() {
    isEditingName.value = false;

    const url = `${process.env.VUE_APP_BACKEND_URL}/change_animation_name/`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'session-id': sessionId
            },
            body: JSON.stringify({
                hashed_id: animationId,
                new_name: animationName.value
            })
        });

        if (!response.ok) {
            throw new Error(`Failed to update animation name. Status: ${response.status}`);
        }

        const result = await response.json();

        if (result.success) {
            console.log('Animation name updated successfully');
        } else {
            console.error('Failed to update animation name');
        }
    } catch (error) {
        console.error('Error updating animation name:', error);
    }
}

function handleDownloadIconClick() {
    showFormatDropdown.value = !showFormatDropdown.value;
}

function handleOpenSettings() {
    showSettingsDropdown.value = !showSettingsDropdown.value;
}

function handleHomeButton() {
    router.push('/app/home');
}

async function handleDownload(selectedFormat) {
    await downloadModelFile(selectedFormat);
    showFormatDropdown.value = false;
}

async function getAnimationInfo() {
    const url = `${process.env.VUE_APP_BACKEND_URL}/animation_details/?hashed_id=${animationId}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'session-id': sessionId
            },
        });

        if (!response.ok) {
            throw new Error(`Failed to load animation info. Status: ${response.status}`);
        }

        const data = await response.json();
        animationName.value = data.animation_name;
        isFavorite.value = data.is_favorite
        promt.value = data.promt
    } catch (error) {
        console.error('Error fetching project items:', error);
    }
}

async function downloadModelFile(extension) {
    isDownloading.value = true;
    loadingProgress.value = 0;

    const modelUrl = `${process.env.VUE_APP_BACKEND_URL}/download-animation/?hashed_id=${animationId}&extension=${extension}`;

    try {
        const response = await fetch(modelUrl, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'session-id': sessionId,
            },
        });

        if (!response.ok) {
            throw new Error("Failed to download model file");
        }

        const contentLength = response.headers.get("content-length");
        const total = parseInt(contentLength, 10);
        let received = 0;

        const reader = response.body.getReader();
        const stream = new ReadableStream({
            start(controller) {
                function push() {
                    reader.read().then(({ done, value }) => {
                        if (done) {
                            controller.close();
                            return;
                        }

                        received += value.length;
                        loadingProgress.value = received / total;

                        controller.enqueue(value);
                        push();
                    });
                }
                push();
            },
        });

        const blob = await new Response(stream).blob();
        const downloadUrl = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `model.${extension}`;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        console.error("Error downloading model file:", error);
    } finally {
        isDownloading.value = false;
        loadingProgress.value = 0;
    }
}


async function toggleFavorite() {
    try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/favorite`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'session-id': sessionId
            },
            body: JSON.stringify({ hashed_id: animationId })
        });

        if (!response.ok) {
            throw new Error('Failed to set favorite');
        }

        const result = await response.json();
        if (result.message) {
            isFavorite.value = !isFavorite.value;
        } else {
            console.error('Failed to update favorite status');
        }
    } catch (error) {
        console.error('Error setting favorite:', error);
    }
}

function closeDropdown() {
    if (isDownloading.value) return;
    showFormatDropdown.value = false;
}

function handleCloseSettings() {
    showSettingsDropdown.value = false;
}

function handleOutsideClick(event) {
    const settingsDropdown = document.querySelector(".settings-container");
    const formatDropdown = document.querySelector(".download-container");

    if (showSettingsDropdown.value && settingsDropdown && !settingsDropdown.contains(event.target)) {
        showSettingsDropdown.value = false;
    }

    if (showFormatDropdown.value && formatDropdown && !formatDropdown.contains(event.target)) {
        showFormatDropdown.value = false;
    }
}

onMounted(() => {
    document.addEventListener("click", handleOutsideClick);

    if (animationId) {
        getAnimationInfo();
    }
});

onBeforeUnmount(() => {
    document.removeEventListener("click", handleOutsideClick);
});

</script>

<style scoped>
.viewer-wrapper {
    padding: 1%;
}

.anim-view-header {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center
}

.settings-container,
.download-container {
    position: relative;
    display: inline-block;
}

.controls {
    display: flex;
    align-items: center;
    justify-content: center
}

.settings-btn {
    margin-right: .5rem;
}

.locked {
    cursor: not-allowed;
}

.modal-window {
    position: absolute;
    top: 110%;
    left: 50%;
    transform: translateX(-90%);
    background-color: rgba(42, 41, 42, 1);
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.modal-label {
    padding-bottom: 1rem;
    color: rgba(255, 255, 255, 0.4);
    text-align: left;
}

.format-buttons {
    display: flex;
}

.format-btn {
    padding: .4rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    cursor: pointer;
}

.format-btn:hover {
    background-color: rgba(255, 255, 255, 0.5);
    transition-duration: .4s;
}

.format-btn:not(:first-child) {
    margin-left: .7rem;
}

.animation-details {
    width: 20vw;
}

.favorite-btn {
    cursor: pointer;
    text-align: left;
    margin-bottom: .7rem;
}

.name,
.prompt {
    text-align: left;
    margin-bottom: .7rem;
}

.name-label {
    cursor: pointer;
}

.name input {
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    width: 100%;
}

.progress-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.progress-circle {
    display: block;
}

.speed-slider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
}

.speed-value {
    padding: 0;
}

</style>