<template>
  <div class="plan-card">
    <div class="plan-card__info">
      <p class="plan-card__title">{{ planTitle }}</p>

      <p class="plan-card__price header-2">{{ displayPrice }}</p>

      <p class="plan-card__description caption">{{ planDescription }}</p>
    </div>

    <primary-btn
      @click="onSelectPlan"
      :disabled="isCurrentPlan"
    >
      {{ buttonText }}
    </primary-btn>

    <ul class="plan-card__features caption" v-if="features.length">
      <li
        class="plan-card__feature-item"
        v-for="(feature, index) in features"
        :key="index"
      >
        {{ feature }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import PrimaryBtn from "@/components/PrimaryBtn.vue";

import { defineProps, defineEmits, computed } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  planTitle: {
    type: String,
    required: true,
  },
  planDescription: {
    type: String,
    default: "",
  },
  priceMonthly: {
    type: [Number, String],
    required: true,
  },
  priceAnnual: {
    type: [Number, String],
    required: true,
  },
  isAnnual: {
    type: Boolean,
    default: false,
  },
  features: {
    type: Array,
    default: () => [],
  },
  btnLabel: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["selectPlan"]);

const store = useStore()

const isCurrentPlan = computed(() => {
  const activePlan = store.state.activePlan;
  return activePlan === props.planTitle;
});

const buttonText = computed(() => {
  if (isCurrentPlan.value) {
    return "Current Plan";
  }
  return props.btnLabel || "Subscribe";
});

const displayPrice = computed(() => {
  const currentPrice = props.isAnnual ? props.priceAnnual : props.priceMonthly;

  if (typeof currentPrice === "number") {
    return `$${currentPrice} / month`;
  } else {
    return currentPrice;
  }
});

function onSelectPlan() {
  if (!isCurrentPlan.value) {
    emit("selectPlan", {
      title: props.planTitle,
      price: props.isAnnual ? props.priceAnnual : props.priceMonthly,
      description: props.planDescription,
      features: props.features,
    });
  }
}
</script>

<style scoped>
.plan-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  padding: 16px 20px;
}

.plan-card__info {
  height: 35%;
}

.plan-card__title {
  color: rgba(255, 255, 255, 0.7);
}

.plan-card__price {
  margin-bottom: 8px;
}

.plan-card__description {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 16px;
  font-size: .8rem;
}

.plan-card__features {
  list-style: disc;
  padding: 0;
  padding-left: 20px;
  margin-bottom: 16px;
}

.plan-card__feature-item {
  text-align: left;
  margin: 4px 0;
  color: rgba(255, 255, 255, 0.7);
}

.plan-card__button {
  width: 100%;
  padding: 12px;
  background-color: #5f77ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.plan-card__button:hover {
  background-color: #4355c8;
}
</style>
