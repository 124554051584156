<template>
    <div class="page-wrapper">
        <header class="header">
            <div class="control-btn" @click="handleBackBtn">
                <BaseIcon iconName="chevronLeft"></BaseIcon>
            </div>

            <div class="header-content">
                <img src="@/assets/logo.png" alt="Logo" class="logo" />
                <h1 class="header-1">AvaCapo</h1>
            </div>
        </header>

        <div class="page-content">
            <router-view></router-view>
        </div>

        <footer>
            <p class="caption">Term of use | Privacy policy</p>
        </footer>
    </div>
</template>

<script setup>
import BaseIcon from '@/components/BaseIcon.vue';
import { useRouter } from 'vue-router';
const router = useRouter();

function handleBackBtn() {
    router.push('app');
}
</script>

<style scoped>
    .page-wrapper {
        display: flex;
        height: 100vh;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 1vh 2vw;
    }

    .header-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        padding-right: 46px;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 10vh;
    }

    .control-btn {
        flex-shrink: 0;
    }

    .logo {
        height: 50px;
    }

    .page-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    footer {
        text-align: center;
    }

    .caption {
        color: rgba(255, 255, 255, .4);
    }

    @media (max-width: 970px) {
        .page-content {
            height: auto;
        }
    }
</style>